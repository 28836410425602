import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import UpdateAt from '@beelineloans/cx-library/src/components/layout/UpdateAt';
import { Breakpoint, H2, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import { ButtonText } from '../../../shared/GatsbyButton';

const headlineBlock = <Breakpoint smallBreakpoint={<>Affiliated businesses</>} largeBreakpoint={<>Affiliated businesses</>} />;

const Title = styled(H2)`
  margin-bottom: 20px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const LocalParagraph = styled(Paragraph)`
  margin: 0;
`;

const AffiliatedBusinesses = () => (
  <Template
    headline={headlineBlock}
    headerProps={{
      hideButton: true
    }}
  >
    <SEO path={CONST.LINKS.MAIN.AFFILIATED_BUSINESSES} title="Affiliated Businesses" description="Information about Beeline's affiliated businesses." />
    <UpdateAt date="May 2024" />
    <Container>
      <Title>Affiliated businesses</Title>
      <SubContainer>
        <ButtonText selected dark newWindow to={CONST.LINKS.EXTERNAL.TITLE.HOME}>
          Beeline Title, LLC
        </ButtonText>
        <LocalParagraph>Beeline Settlement Services, LLC</LocalParagraph>
        <LocalParagraph>Beeline Texas Title, LLC</LocalParagraph>
        <LocalParagraph>Beeline Title Agency, LLC</LocalParagraph>
        <ButtonText selected dark newWindow to="https://magicblocks.ai/">
          MagicBlocks, Inc.
        </ButtonText>
        <ButtonText selected dark newWindow to="https://www.nimbletitle.com/">
          Nimble Title, LLC
        </ButtonText>
        <LocalParagraph>Nimble Settlement Services, LLC</LocalParagraph>
        <LocalParagraph>Nimble Texas Title, LLC</LocalParagraph>
        <LocalParagraph>Nimble Title Agency, LLC</LocalParagraph>
      </SubContainer>
    </Container>
  </Template>
);

export default AffiliatedBusinesses;
